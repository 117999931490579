import Flex from 'imports/core/ui/atoms/Flex';
import { withBoards } from 'imports/job-tracking/api/context/boards.context';
import { isSavedPodioJob } from 'imports/job-tracking/api/helper';
import { useRef, useState } from 'react';
import { CellMeasurer, CellMeasurerCache, InfiniteLoader, List, WindowScroller } from 'react-virtualized';
import styled from 'styled-components';
import { useSearchStore } from 'zustand/SearchStore';
import JobCard from '/imports/job-tracking/ui/components/JobCard';
import { extractJobID } from 'imports/job-tracking/api/api';

const VirtualizedInfinteScroll = ({ handleDeleteConfirm, handleDirectJobAdd, onClickCard, next }) => {
  const {
    state: { boards },
  } = withBoards();
  const { jobsData, podioJobsData, pagination, activeCardData } = useSearchStore();
  const [rowCount, setRowCount] = useState(jobsData.length);
  const hasMoreData = pagination?.currentPage < pagination?.totalPages;
  const loadMoreRows = async () => {
    if (hasMoreData) {
      await next();
      setRowCount(jobsData.length);
    }
  };

  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: true,
    }),
  );

  const rowRenderer = ({ index, key, style, parent }) => {
    const job = jobsData[index];

    if (!job) {
      return (
        <div key={key} style={style}>
          <LoadingText>Loading...</LoadingText>
        </div>
      );
    } else {
      job.id = extractJobID(job.url);
    }

    return (
      <CellMeasurer key={key} cache={cache.current} parent={parent} columnIndex={0} rowIndex={index}>
        {({ measure }) => (
          <div style={style}>
            <JobCard
              job={job}
              id={job?._id}
              onClickCard={onClickCard}
              isPlain
              isDropDown
              isSaved={!!boards?.[job?._id]}
              border={boards?.[job?._id]?.colorCode || null}
              selected={job?.jobId === activeCardData?.jobId}
              searchPage
              parentsavedPodioJob={isSavedPodioJob(podioJobsData, job)}
              handleDeleteConfirm={handleDeleteConfirm}
              isWishlist={boards?.[job?._id]?.position[0] === 0}
              handleDirectJobAdd={handleDirectJobAdd}
              onLoad={measure}
            />
          </div>
        )}
      </CellMeasurer>
    );
  };

  return (
    <ResponsiveScroll fullWidth>
      <WindowScroller>
        {({ isScrolling, scrollTop }) => (
          <InfiniteLoader
            isRowLoaded={({ index }) => !!jobsData[index]}
            loadMoreRows={loadMoreRows}
            rowCount={jobsData.length}
          >
            {({ registerChild }) => (
              <List
                autoHeight
                width={370}
                height={700}
                rowCount={jobsData.length}
                rowHeight={cache.current.rowHeight}
                isScrolling={isScrolling}
                scrollTop={scrollTop}
                rowRenderer={rowRenderer}
                onRowsRendered={({ stopIndex }) => {
                  if (hasMoreData && stopIndex >= rowCount - 1) loadMoreRows();
                }}
                overscanRowCount={5}
                ref={registerChild}
              />
            )}
          </InfiniteLoader>
        )}
      </WindowScroller>
    </ResponsiveScroll>
  );
};

export default VirtualizedInfinteScroll;

const ResponsiveScroll = styled(Flex)`
  @media (max-width: 768px) {
    .ReactVirtualized__Grid {
      width: 100% !important;
    }

    .ReactVirtualized__List {
      width: 100% !important;
    }

    .ReactVirtualized__Grid__innerScrollContainer {
      max-width: 100% !important;
    }
  }
`;

const LoadingText = styled.div`
  text-align: center;
  padding: 3px;
`;
