import { useMutation } from '@tanstack/react-query';
import {
  cityDefaultSuggestion,
  getIndeedJobDetails,
  getSearchJob,
  getSearchJob8,
  savedJobs8,
  savedJobsOnPodio,
  saveFeed,
  updatFeedApi,
} from 'imports/job-tracking/api/api';
import nookies from 'nookies';
import { useSearchStore } from 'zustand/SearchStore';
import useFeedStore from 'zustand/feedStore';
import useTracking from 'imports/core/hooks/useTracking';
import { useGeneralStore } from 'zustand/generalStore';
import nProgress from 'nprogress';
import { useEffect } from 'react';

const searchApi = () => {
  const { token } = nookies.get({});
  const {
    setJobsData,
    jobsData,
    setIndeedJobData,
    setLoading,
    setactiveCard,
    setCityDefaultOptions,
    podioJobsData,
    setPodioJobsData,
    setCurrentPage,
    setPagination,
  } = useSearchStore();
  const { updateToFeeds, addToFeeds, saveMantiksDataIntoFeed, renameFeedName, setIcon } = useFeedStore();
  const { setcloseEmojiPicker } = useGeneralStore();
  const { trackEvent } = useTracking();

  const useGetIndeedJobDetails = () => {
    const { mutate, isPending } = useMutation({
      mutationFn: async (data) => {
        nProgress.start();
        if (data.jobData.apiProvider !== 'search-v3') {
          return data;
        }
        return await getIndeedJobDetails({ jobId: data.jobId, company: data.company }, token);
      },
      onSuccess: (data, variables) => {
        const { jobData, isActiveCardDetail } = variables;
        if (data) {
          nProgress.done();
          setIndeedJobData(data);
          setLoading(false);

          if (isActiveCardDetail) {
            let activedata = { ...jobData, description: data?.description };
            setactiveCard(activedata);
          }
        }
      },
      onError: (error) => {
        setLoading(false);
        console.error(error);
      },
    });

    return { mutate, isPending };
  };

  const useGetSearchJob = () => {
    const { mutate, isError, isPending } = useMutation({
      mutationFn: async ({ data, isFirstTime, page = 1 }) => {
        nProgress.start();
        if (isFirstTime) setLoading(true);
        const response = await getSearchJob8(data.title, data.where, page, token);
        return response;
      },
      onSuccess: (jobdata, variables) => {
        const { isFirstTime, page } = variables;
        if (jobdata?.data) {
          nProgress.done();

          const newJobs = jobdata?.data || [];
          setPagination(jobdata?.pagination);

          if (isFirstTime) {
            setJobsData([...newJobs]);
            setCurrentPage(1);
          } else {
            setJobsData([...jobsData, ...newJobs]);
            setCurrentPage(page);
          }

          if (isFirstTime) setLoading(false);
        }
        setLoading(false);
      },
      onError: (error) => {
        setLoading(false);
        console.error(error);
      },
    });

    return { mutate, isPending };
  };

  const useUpdateFeedApi = () => {
    const { mutate } = useMutation({
      mutationFn: async (feedData) => {
        if (feedData.isFeedName) {
          return updatFeedApi({ feedName: feedData.feedName }, feedData.id, token);
        } else if (feedData.isEmojiPicker) {
          return updatFeedApi({ icon: feedData.icon, feedName: feedData.feedName }, feedData.id, token);
        } else {
          return updatFeedApi(feedData.updatedData, feedData.feedId, token);
        }
      },
      onSuccess: (data, variables) => {
        const { isFeedName, id, isEmojiPicker } = variables;
        if (data) {
          trackEvent('feed_update');
          updateToFeeds(data, data.feedId);

          if (isFeedName) {
            renameFeedName(data, id);
          }

          if (isEmojiPicker) {
            setIcon(data, id);
            setcloseEmojiPicker();
          }
        }
      },
      onError: (error) => {
        console.error(error);
      },
    });

    return { mutate };
  };

  const usesSaveFeedData = () => {
    const { mutate } = useMutation({
      mutationFn: async (feedData) => {
        nProgress.start();
        return saveFeed(feedData, token);
      },
      onSuccess: (data) => {
        if (data) {
          nProgress.done();
          addToFeeds(data);
        }
      },
      onError: (error) => {
        console.error(error);
      },
    });
    return { mutate };
  };

  const useCityDefaultSuggestion = () => {
    const { mutate } = useMutation({
      mutationFn: async (CityData) => {
        return cityDefaultSuggestion(CityData.latitude, CityData.longitude, token);
      },
      onSuccess: (data) => {
        if (data) {
          setCityDefaultOptions(data);
        }
      },
      onError: (error) => {
        console.error(error);
      },
    });

    return { mutate };
  };

  const usesavedJobsOnPodio = (setSaveLoading = null) => {
    const { mutate } = useMutation({
      mutationFn: async (JobData) => {
        return savedJobsOnPodio(JobData, token);
      },
      onSuccess: (data) => {
        if (data) {
          let podioJob = [...podioJobsData, data];
          setPodioJobsData(podioJob);
        }
        if (setSaveLoading) setSaveLoading(false);
      },
      onError: (error) => {
        console.error(error);
        if (setSaveLoading) setSaveLoading(false);
      },
    });

    return { mutate };
  };

  const newSavedJob8Data = (setSaveLoading = null) => {
    const { mutate } = useMutation({
      mutationFn: async (JobData) => {
        return savedJobs8(token, JobData);
      },
      onSuccess: (data) => {
        if (data) {
          let podioJob = [...podioJobsData, data];
          setPodioJobsData(podioJob);
        }
        if (setSaveLoading) setSaveLoading(false);
      },
      onError: (error) => {
        console.error(error);
        if (setSaveLoading) setSaveLoading(false);
      },
    });
    return { mutate };
  };

  return {
    useGetSearchJob,
    useUpdateFeedApi,
    usesSaveFeedData,
    useGetIndeedJobDetails,
    useCityDefaultSuggestion,
    usesavedJobsOnPodio,
    newSavedJob8Data,
  };
};

export default searchApi;
