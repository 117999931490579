import { getFeedList, listPodioSavedJobs } from './api';
import useFeedStore from 'zustand/feedStore';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useSearchStore } from 'zustand/SearchStore';

const appApi = (token) => {
  const { setFeeds, feeds } = useFeedStore();
  const { setPodioJobsData } = useSearchStore();

  const useFeedList = (country) => {
    const { data, isError, isLoading } = useQuery({
      queryKey: ['feedListKey', country, token],
      queryFn: async () => {
        if (feeds && feeds.length > 0) return feeds;
        return getFeedList(country, token);
      },
      enabled: !!token,
    });

    useEffect(() => {
      if (data) setFeeds(data);

      if (isError) setFeeds(null);
    }, [data, isError, setFeeds]);

    return { data, isError, isLoading };
  };

  const usePodioSaveJobs = () => {
    const { data, isError, isLoading } = useQuery({
      queryKey: ['PodioSaveJobsKey'],
      queryFn: async () => {
        return listPodioSavedJobs(token);
      },
      enabled: !!token,
    });

    useEffect(() => {
      if (data) setPodioJobsData(data);

      if (isError) setPodioJobsData(null);
    }, [data, isError, setPodioJobsData]);

    return { data, isLoading, isError };
  };

  return { useFeedList, usePodioSaveJobs };
};

export default appApi;
