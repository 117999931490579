import { useCallback } from 'react';
import { useSearchStore } from 'zustand/SearchStore';
import useFeedStore from 'zustand/feedStore';
import { isEqual } from 'lodash';
import searchApi from 'imports/job-tracking/api/searchApi';
import { useSavedFeed } from '/imports/job-tracking/ui/components/hooks/useSavedFeed.js';
import { useUpdateFeed } from '/imports/job-tracking/ui/components/hooks/useUpdateFeed';

export const useOnSave = (feedId, token, isSearchPage) => {
  const { searchTextData, cityTextData, filter } = useSearchStore();
  const { activeFeeds } = useFeedStore();
  const { useUpdateFeedApi } = searchApi();
  const { mutate } = useUpdateFeedApi();
  const { saveFeedCallback } = useSavedFeed(token);
  const { updateFeedCallBack } = useUpdateFeed(feedId, token);

  const onSave = useCallback(
    async (feedData) => {
      if (!feedData) {
        let TitleText = '';
        if (searchTextData && cityTextData) TitleText = `${searchTextData},${cityTextData}`;
        else if (searchTextData) TitleText = searchTextData;
        else if (cityTextData) TitleText = cityTextData;
        isSearchPage ? saveFeedCallback(TitleText) : updateFeedCallBack(TitleText);
      } else {
        const {
          icon,
          feedName,
          bySearch,
          searchText: feedSearchText = '',
          where = '',
          postedDate = '',
          remote = '',
          educationLevels = [],
          salary = [],
          skills = [],
          locations = [],
          jobType = [],
        } = activeFeeds;

        const obj1 = {
          searchText: feedSearchText,
          where,
          postedDate,
          remote,
          educationLevels,
          salary,
          skills,
          locations,
          jobType,
          allowedJobProviders,
        };
        const {
          datePost = '',
          remote: remoteComp = '',
          educationLevel = [],
          salary: salaryComp = [],
          jobType: jobTypeComp = [],
          skills: skillComp = [],
          location = [],
          allowedJobProviders = [],
        } = filter;

        const updatedData = {
          feedName,
          icon: icon || null,
          bySearch: typeof bySearch === 'boolean' ? bySearch : false,
          searchText: searchTextData || null,
          where: cityTextData || null,
          postedDate: datePost || null,
          remote: Array.isArray(remoteComp) ? remoteComp : [],
          educationLevels: educationLevel.length ? educationLevel : [],
          salary: salaryComp || null,
          skills: skillComp.length ? skillComp : [],
          locations: location.length ? location : [],
          jobType: jobTypeComp.length ? jobTypeComp : [],
          allowedJobProviders: allowedJobProviders.length ? allowedJobProviders : [],
        };

        if (!isEqual(obj1, updatedData)) {
          updatedData = {
            ...updatedData,
            icon,
            feedName,
            bySearch,
          };
          let payload = {
            updatedData,
            feedId: activeFeeds.feedId,
          };
          mutate(payload);
        }
      }
      return { feedData };
    },
    [activeFeeds, searchTextData, cityTextData, filter, feedId, token],
  );

  return { onSave };
};
