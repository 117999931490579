import AddMainTitleModal from 'imports/generator/ui/components/AddMainTitleModal';
import { getOnecenterTitleList, updateJob } from 'imports/job-tracking/api/api';
import moment from 'moment';
import { useRouter } from 'next/router';
import nookies from 'nookies';
import { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import { withBoards } from 'imports/job-tracking/api/context/boards.context';
import { savedData } from 'imports/job-tracking/api/helper';
import { useSearchStore } from 'zustand/SearchStore';
import BottomSectionDetails from './BottomSectionDetails';
import env from '/env';
import useIntl from '/imports/core/api/useIntl';
import Flex from '/imports/core/ui/atoms/Flex';
import Chip from '/imports/job-tracking/ui/atoms/Chip';
import { compareStrings, filteredRenderedJobType, getPortalName, processText } from '/lib/helpers';

const JobDescription = ({ $noPadding, isMobileCompnayDetail, isSaved }) => {
  const { t } = useIntl();
  const { token } = nookies.get({});
  const {
    query: { template },
  } = useRouter();
  const {
    state: { boards },
  } = withBoards();
  const { activeCardData, IndeedJobData } = useSearchStore();
  const data = !!savedData(boards, activeCardData) ? savedData(boards, activeCardData) : activeCardData;
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mainTitle, setMainTitle] = useState('');
  const [suggestion, setSuggestion] = useState(false);
  const isProd = env.NODE_ENV === 'production';
  const jobType = data?.contract || data?.jobType;
  let show = true;
  const hasSalary = useMemo(() => (data?.salary ? true : false), [data]);
  const isTracking = template === 'tracking';
  const hasInformationDetails =
    hasSalary ||
    (jobType?.length > 0 && jobType !== null) ||
    data?.contractCategory ||
    (data?.shift?.length > 0 && data?.shift !== null) ||
    (data?.benefits?.length > 0 && data?.benefits !== null) ||
    data.portal ||
    data.portals;
  const hasBottomSectionDetails =
    (data?.shift?.length > 0 && data?.shift !== null) ||
    (data.benefits?.length > 0 && data.benefits !== null) ||
    data.portal ||
    data.portals;

  useEffect(() => {
    const suggestion = async () => {
      const response = await getOnecenterTitleList(token);
      setSuggestion(response);
    };
    suggestion();
  }, []);

  const handleAddMainTitle = () => {
    setOpen(!open);
  };
  const AddMainTitle = async (body) => {
    setLoading(true);
    setMainTitle(body.label);
    const option = suggestion.find(({ value }) => value === body.value);
    try {
      const response = await updateJob(
        {
          ...body,
          cleanedTitleId: data.cleanedTitleId,
          code: option ? option.code : '',
          cleanedTitle: data.cleanedTitle,
        },
        token,
      );
      if (response.length) {
        setOpen(!open);
        setLoading(false);
      }
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }
  };
  const onClose = () => {
    setOpen(!open);
  };
  return (
    <>
      <Wrapper
        direction="column"
        fullWidth
        $noPadding={$noPadding}
        isMobileCompnayDetail={isMobileCompnayDetail}
        isSaved={isTracking && isSaved}
      >
        {hasInformationDetails && (
          <JobDetails
            fullWidth
            direction="column"
            isMobileCompnayDetail={isMobileCompnayDetail}
            isSaved={isTracking && isSaved}
          >
            <Title>{t('jobtrack_job_description_details_text')}</Title>
            <TagWrapper direction="column" fullWidth>
              {hasSalary && (
                <SalaryWrap direction="column">
                  <SubTitle>{t('jobtrack_job_description_salary_text')}</SubTitle>
                  {data?.salary && (
                    <Chip
                      colorCode={3}
                      alignItems="center"
                      value={isProd ? data.salary : `OS: ${data.salary}`}
                      fullWidth
                      withSalary
                    />
                  )}
                  {data?.providerSalary && (
                    <Chip
                      colorCode={3}
                      alignItems="center"
                      value={`${getPortalName(data.portal)}: ${data?.providerSalary}`}
                      fullWidth
                      withMarginBottom
                      withSalary
                    />
                  )}
                </SalaryWrap>
              )}

              {((jobType?.length > 0 && jobType !== null) || data?.contractCategory) && (
                <ReqTag direction="column">
                  <SubTitle>{t('jobtrack_job_description_type_text')}</SubTitle>
                  <ReqTag wrap="wrap">
                    {filteredRenderedJobType(jobType)?.map((k, i) => {
                      if (data?.contractCategory && compareStrings(k, data?.contractCategory)) show = false;
                      return (
                        <Chip
                          colorCode={3}
                          alignItems="center"
                          value={isProd ? k : `OS: ${k}`}
                          fullWidth
                          key={i}
                          withJob
                        />
                      );
                    })}
                    {data?.contractCategory && show && (
                      <Chip
                        colorCode={3}
                        alignItems="center"
                        value={`${
                          !isProd ? `${getPortalName(data.portal)}: ${data?.contractCategory}` : data?.contractCategory
                        }`}
                        fullWidth
                        withJob
                      />
                    )}
                  </ReqTag>
                </ReqTag>
              )}
              {hasBottomSectionDetails && (
                <BottomSectionDetails
                  onClick={handleAddMainTitle}
                  isMobileCompnayDetail={isMobileCompnayDetail}
                  mainTitle={mainTitle}
                  isSaved={isSaved}
                />
              )}
            </TagWrapper>
          </JobDetails>
        )}

        <DescriptionFlex direction="column" isMobileCompnayDetail={isMobileCompnayDetail}>
          <DescriptionText
            dangerouslySetInnerHTML={{
              __html: processText(
                (activeCardData.apiProvider !== 'search-v3'
                  ? activeCardData.metadata.summary
                  : IndeedJobData?.description) || 'Description unavailable!!',
              ),
            }}
          />
        </DescriptionFlex>
        {data?.requirements && (
          <Requriments fullWidth direction="column">
            <SubTitle>{t('jobtrack_job_description_requirements_text')}</SubTitle>
            <StyledUL>{data?.requirements && data?.requirements.map((k, i) => <li key={i}>{k}</li>)}</StyledUL>
          </Requriments>
        )}
        <JobHiring direction="column" fullWidth isMobileCompnayDetail={isMobileCompnayDetail}>
          <HireTitle>{t('jobtrack_job_description_hiring_insights_text')}</HireTitle>
          <HireSubTitle>{t('jobtrack_job_description_activity_text')}</HireSubTitle>
          <StyledUL>
            <li>
              {(isSaved
                ? `${t('jobtrack_job_card_period_saved_text')} `
                : `${t('jobtrack_job_card_period_posted_text')} `) + moment(data.createdAt).fromNow()}
            </li>
          </StyledUL>
        </JobHiring>
      </Wrapper>
      <AddMainTitleModal
        open={open}
        onClose={onClose}
        AddMainTitle={AddMainTitle}
        suggestions={suggestion}
        setSelectedOption={setSelectedOption}
        selectedOption={selectedOption}
        loading={loading}
      />
    </>
  );
};

export default JobDescription;

const DescriptionFlex = styled(Flex)`
  padding: 12px 24px 24px;
  padding-right: 0;
  gap: 16px;
  ${({ isMobileCompnayDetail }) =>
    isMobileCompnayDetail &&
    css`
      padding: 0;
      padding-top: 12px;
    `}
`;

const HireSubTitle = styled.div`
  ${({ theme }) => theme.jobTrack.renderFonts('SemiBold', '15', 'var(--dark-values-black)', '22px')};
`;

const HireTitle = styled.div`
  ${({ theme }) => theme.jobTrack.renderFonts('SemiBold', '17', 'var(--dark-values-black)', '24px')};
`;

const JobHiring = styled(Flex)`
  padding: 24px;
  gap: 8px;
  ${({ isMobileCompnayDetail }) =>
    isMobileCompnayDetail &&
    css`
      padding: 0;
      padding: 24px 0;
    `}
`;

const StyledUL = styled.ul`
  margin: 0;
  padding-left: 17px;
  li {
    ${({ theme }) => theme.jobTrack.renderFonts('Medium', '15', 'var(--dark-values-dark-regular)', '22px')};
  }
`;

const Requriments = styled(Flex)`
  padding: 32px;
  padding-top: 0;
  gap: 16px;
  border-bottom: 1px solid #ececed;
`;

const ReqTag = styled(Flex)`
  gap: 4px;
`;

const SubTitle = styled.div`
  user-select: text;
  ${({ theme }) => theme.jobTrack.renderFonts('SemiBold', '15', 'var(--dark-values-black)', '22px')};
`;

const SalaryWrap = styled(Flex)`
  gap: 6px;
`;
const Title = styled.div`
  ${({ theme }) => theme.jobTrack.renderFonts('SemiBold', '17', 'var(--dark-values-black)', '24px')};
`;

const JobDetails = styled(Flex)`
  padding: 12px 24px;
  padding-right: 0;
  gap: 8px;
  border-bottom: 1px solid var(--grayscale-n300);
  > * {
    user-select: text;
  }
  ${({ isMobileCompnayDetail }) =>
    isMobileCompnayDetail &&
    css`
      padding: 12px 0;
    `}
  ${({ isSaved }) =>
    isSaved &&
    css`
      border: none;
    `}
`;

const DescriptionText = styled.div`
  user-select: text;
  word-break: break-word;
  ${({ theme }) => theme.jobTrack.renderFonts('Medium', '15', 'var(--dark-values-dark-regular)', '22px')};
  > * {
    user-select: text;
  }
`;

const Wrapper = styled(Flex)`
  background-color: var(--light-values-white);
  border-top: 1px solid var(--grayscale-n300);
  ${({ $noPadding }) =>
    $noPadding &&
    css`
      padding: 0;
    `};
  ${({ isSaved }) =>
    isSaved &&
    css`
      border: none;
    `}
`;

const TagWrapper = styled(Flex)`
  gap: 16px;
  user-select: text;
`;
